import type { SvelteComponent } from "svelte"

type Options = {
  /** Custom HTML element name */
  name: string
  /** URL pointing to Svelte component to make widget from  */
  src: string | URL
  /** The Svelte component methods names to export from the Widget custom HTML element */
  exports?: string[]
  /** The Svelte component events names to export from the Widget custom HTML element */
  events?: string[]
}

interface WidgetFactory extends HTMLIFrameElement {
  contentWindow: HTMLIFrameElement["contentWindow"] & {
    create(...args: ConstructorParameters<typeof SvelteComponent>): SvelteComponent
  }
}

/**
 * The widget is an abstraction for a Svelte component isolation.
 * It isolates the component styles via wrapping the component in [custom element](https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements)
 * and rendering it to [Shadow DOM](https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_shadow_DOM).
 * It isolates the component scripts via leveraging [Realms](https://github.com/tc39/proposal-shadowrealm).
 *
 * Technical notes on Realms:
 * Realms have their own global environment independent from the parent application.
 * It means the globals like `window`, `document`, and built-ins like `HTMLElement` are different between the Realm and the parent application.
 *
 * Hence, a code running inside Realm and trying to add an event listener to window or document adds the listener to _the Realms instance of `window` or `document`_.
 * To workaround it, [node.ownerDocument](https://developer.mozilla.org/en-US/docs/Web/API/Node/ownerDocument) should be used instead of direct access to `document`,
 * and [node.ownerDocument.defaultView](https://developer.mozilla.org/en-US/docs/Web/API/Document/defaultView) to reference the real `window`.
 *
 * Also checks like `instanceof HTMLElement` may fail if called inside the Realm against a DOM element of the parent application.
 * E.g the code `anHTMLElementCreatedInTheParentApp instanceof HTMLElement` called inside Realm will always return `false`. The opposite is also true.
 */

const showDomMode = import.meta.env.VITE_OPEN_SHADOW_DOM === "true" ? "open" : "closed"

export default ({ name, src, exports = [], events = [] }: Options) => {
  if (typeof customElements === "undefined") return // SSR
  if (customElements.get(name)) return // already defined

  customElements.define(
    name,
    class extends HTMLElement {
      _bridge: Promise<SvelteComponent>

      constructor() {
        super()

        // Shopify specific fix for double click on mobule devices
        this.addEventListener("touchend", (e: Event) => e.stopPropagation())

        if (typeof src !== "string") src = `${src}`

        /** Adopted from https://www.youtube.com/watch?v=RcWqY4kcjDY */

        const root = this.attachShadow({ mode: showDomMode })

        const link = document.createElement("link")
        link.href = src
        link.rel = "modulepreload preload"
        link.as = "script"
        link.crossOrigin = ""
        this.prepend(link)

        const style = document.createElement("style")
        style.textContent = ":host{display:contents}"
        root.append(style)

        /** The iframe is used as a Realm, check out the [video](https://www.youtube.com/watch?v=RcWqY4kcjDY) to grab the idea */
        const iframe = document.createElement("iframe") as WidgetFactory
        iframe.style.display = "none"

        /**
         * The script element acts as Realm.importValue()
         * @see https://github.com/tc39/proposal-shadowrealm/blob/main/explainer.md#api-typescript-format
         */
        const script = document.createElement("script")
        script.type = "module"
        script.src = URL.createObjectURL(
          new Blob([`import c from"${src}";self.create=(o)=>new c(o)`], {
            type: "text/javascript",
          })
        )

        this._bridge = new Promise((resolve) => (script.onload = resolve))
          .then(() => {
            const props = { ...attrs2props(this.attributes) }

            if (props.merchantId) {
              return fetch(
                `${import.meta.env.VITE_TINT_MERCHANT_CDN}/${props.merchantId}/config.json`
              )
                .then((r) => r.text())
                .then((dataStr) => {
                  const data = JSON.parse(dataStr)

                  const addedProps: { [p: string]: string } = {
                    token: data.config,
                  }

                  if (props.skipLoadSdkToken === undefined) {
                    addedProps.sdkToken = data.sdkToken
                  }

                  return {
                    ...props,
                    ...addedProps,
                  }
                })
            } else {
              return props
            }
          })
          .then((props) =>
            iframe.contentWindow.create({
              target: root,
              props,
            })
          )

        for (const name of exports)
          Object.defineProperty(this, name, {
            value: (...args: any[]) => this._bridge.then((component) => component[name](...args)),
            enumerable: false,
            configurable: false,
            writable: false,
          })

        for (const type of events)
          this._bridge.then((component) =>
            component.$on(type, (event) => this.dispatchEvent(event))
          )

        // Firefox requires the iframe element to be fully loaded before appending the script element
        iframe.onload = () => iframe.contentDocument!.head.append(script)

        document.head.append(iframe)
      }

      disconnectedCallback() {
        // this._bridge.then((component) => component.$destroy())
      }
    }
  )
}

/** cast HTMLElement.attributes to Svelte component props */
const attrs2props = (attrs: Element["attributes"]) =>
  Object.fromEntries(Array.from(attrs, ({ name, value }) => [camelCase(name), value]))

/** "foo-bar-baz" -> "fooBarBaz" */
const camelCase = (str: string) => str.replace(/-(\w)/g, (_, c) => c.toUpperCase())
